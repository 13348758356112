import { useEffect } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { postEstimate, getEstimate } from '../fetchPromises'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useClaimedState, useResupplySetting } from 'modules/resupply2'

function useResupplyMimicInvoice( type, setInvoice, setError ) {

  const claimedItems = useClaimedState()[0]
  const { resupplyAOBSettings, patientId } = useResupplySetting()[0]

  useEffect( () => {
    // if coming from resupply we will not have a hash
    // in this case check if we have claimed items and an order was placed in resupply and if not send them to homepage
    if ( type !== `hash` && patientId ) postResupplyEstimate()
  }, [] )

  const postResupplyEstimate = async () => {
    const postEstimateResponse = await postEstimate({
      patient_id: patientId,
      items: claimedItems.map( ( item ) => {
        return {
          dme_id: item?.hcpc_id,
          qty: item?.max_qty
        }
      })
    })
      .catch( () => {
        return handleResupplyEstimateError( `post` )
      })

    if ( postEstimateResponse?.meta?.status !== `OK` || !postEstimateResponse?.data?.batch_id ) return handleResupplyEstimateError( `post` )
    await getResupplyEstimate( postEstimateResponse.data.batch_id )
  }

  const getResupplyEstimate = async ( batchId ) => {
    const getEstimateResponse = await getEstimate( batchId )
      .catch( () => {
        return handleResupplyEstimateError( `get` )
      })

    if ( getEstimateResponse?.meta?.status !== `OK` || !getEstimateResponse?.data?.length ) return handleResupplyEstimateError( `get` )

    const {
      deductible,
      deductible_remaining,
      monthly_max,
      total_charge,
      coinsurance_percentage,
      number_of_months,
      est_monthly_due,
      total_to_collect
    } = getEstimateResponse.data[0]

    // map resupply data to invoice data
    setInvoice( Object.assign({}, {
      ...resupplyAOBSettings,
      deductible,
      deductible_remaining,
      coinsurance_percentage,
      monthly_max,
      number_of_months,
      est_monthly_due,
      total_ins_payment: total_charge - total_to_collect,
      total_to_collect,
      total_patient_owes: total_to_collect,
      allow_invoice_edit_flag: false,
      emergency_contact_info_required_flag: false,
      scheduling_required_flag: false,
      details: claimedItems.map( ( item ) => {
        return {
          dme_id: item.hcpc_id,
          item_description: item.description,
          rental_flag: false,
          quantity: item.max_qty
        }
      })
    }) )
  }

  const handleResupplyEstimateError = ( stage ) => {
    setError( `Oops! Looks like something went wrong attempting to get an estimate for your order, please call us at ${AF_SUPPORT_PHONE_NUMBER} to resolve this issue.` )

    return reportToSentry( new Error( `AOB Estimate Error: Error generating estimation from resupply patient` ), {
      patient_id: patientId,
      items: claimedItems,
      stage
    })
  }

  return
}

export default useResupplyMimicInvoice