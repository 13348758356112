export const RESUPPLY_PORTAL_PATH = `/resupply/claim/*`

export const RESUPPLY_CLAIMS_PATH = `/resupply/claim`
export const RESUPPLY_MASK_REPLACEMENT = `/resupply/claim/mask-replacement`
export const RESUPPLY_CLAIMS_ITEMS_PATH = `/resupply/claim/items`
export const RESUPPLY_CHECKLIST_PATH = `/resupply/claim/checklist`
export const RESUPPLY_ADDRESS_PATH = `/resupply/claim/address`
export const RESUPPLY_DOCTOR_PATH = `/resupply/claim/doctor`
export const RESUPPLY_INSURANCE_PATH = `/resupply/claim/insurance`
export const RESUPPLY_CONFIRM_PATH = `/resupply/claim/confirm`
export const RESUPPLY_THANK_YOU_PATH = `/resupply/claim/thank-you`

export const RESUPPLY_ALL_PAGES = [
  RESUPPLY_MASK_REPLACEMENT,
  RESUPPLY_CLAIMS_ITEMS_PATH,
  RESUPPLY_CHECKLIST_PATH,
  RESUPPLY_ADDRESS_PATH,
  RESUPPLY_DOCTOR_PATH,
  RESUPPLY_INSURANCE_PATH,
  RESUPPLY_CONFIRM_PATH,
  RESUPPLY_THANK_YOU_PATH
]
