import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { personWithMaskWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'

type OnlineMaskFitStepProps = {
    stepStatus: boolean;
    isCurrentStep?: boolean;
    sovasageUrl: string;
 }

function OnlineMaskFitStep({ stepStatus, isCurrentStep, sovasageUrl } : OnlineMaskFitStepProps ) : JSX.Element {
  // handling the copy for this step here since the stepLinkUrl is dynamic
  const onlineMaskFitCopy = {
    notCompletedStepHeading: `Requires Online Mask Fit`,
    currentStepDetails: `Please complete your online mask fit to proceed with your order.`,
    stepLinkText: `Complete Online Mask Fit`,
    stepLinkUrl: sovasageUrl,
    stepToolTip: `Our online mask fitting technology will allow you to answer questions and scan your face to help identify the best mask fit for you.`,
    completedStepHeading: `Mask Fit Completed`
  }

  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={personWithMaskWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={onlineMaskFitCopy}
      />
    </div>
  )
}

export default OnlineMaskFitStep