import {
  RESUPPLY_ALL_PAGES,
  RESUPPLY_CHECKLIST_PATH,
  RESUPPLY_CLAIMS_ITEMS_PATH,
  RESUPPLY_CLAIMS_PATH,
  RESUPPLY_DOCTOR_PATH,
  RESUPPLY_INSURANCE_PATH,
  RESUPPLY_MASK_REPLACEMENT,
  RESUPPLY_THANK_YOU_PATH
} from './routes'

import PageNavigator from 'components/pageNavigator'
import Claims from './Claims'
import MedicareChecklist from './MedicareChecklist'
import Address from './Address'
import DoctorController from './Doctor'
import Insurance from './Insurance'
import Confirm from './Confirm/Confirm'
import ThankYou from './ThankYou/ThankYou'
import { ProgressHeader } from 'components/resupply2'
import { useEffect, useState } from 'react'
import { useClaimedState, useResupplySetting } from './hooks'
import { ResupplyStorage } from 'apollo/storage'
import { useFetchResupplyOptions } from './hooks/resupplyOptions'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { ReplacementMaskSelection } from './ReplacementMaskSelection'

export default function ResupplyController() : JSX.Element {

  const [ currentPageIndex, setCurrentPageIndex ] = useState<number>( 0 )
  const [ pageNames, setPageNames ] = useState<string[]>( RESUPPLY_ALL_PAGES )

  const { updateDoctorRequired, updatePrimaryPayerStatus, updateSecondaryPayerStatus, medicareChecklistRequired } = useResupplySetting()[0]
  const resupplyOptionResponse = useFetchResupplyOptions()
  const claimedItems = useClaimedState()[0]
  const navigate = useNavigate()

  // useMedchatWidget( `d1vPD43-_0Shzxc9KBWG0w` )

  useEffect( () => {
    if ( window.location.pathname !== RESUPPLY_CLAIMS_PATH && window.location.pathname !== RESUPPLY_CLAIMS_ITEMS_PATH ) {
      navigate( RESUPPLY_CLAIMS_PATH )
    }
  }, [] )

  useEffect( () => {
    const displayPages = [ ...RESUPPLY_ALL_PAGES ]
    if ( !resupplyOptionResponse?.loading ) {
      if ( !resupplyOptionResponse?.replaceMaskOptions?.length ) handleRemovePage( displayPages, RESUPPLY_MASK_REPLACEMENT )
      if ( updateDoctorRequired === `none` ) handleRemovePage( displayPages, RESUPPLY_DOCTOR_PATH )
      if ( !medicareChecklistRequired ) handleRemovePage( displayPages, RESUPPLY_CHECKLIST_PATH )
      if ( updatePrimaryPayerStatus === `none` && updateSecondaryPayerStatus === `none` ) handleRemovePage( displayPages, RESUPPLY_INSURANCE_PATH )
      setPageNames( [ ...displayPages ] )
    }

  }, [ resupplyOptionResponse?.loading ] )


  const pageChangeHandler = ( nextPageIndex: number ) => {
    setCurrentPageIndex( nextPageIndex )
  }

  const handleRemovePage = ( pages: string[], removePage: string ) => {
    pages.splice( pages.findIndex( page => {
      return page === removePage
    }), 1 )
  }

  if ( ResupplyStorage.getOrderPlaced() && claimedItems?.length ) return <ThankYou />

  if ( resupplyOptionResponse?.loading ) return (
    <div className="my-24">
      <LoadingSpinner
        messageStyling="font-light text-lg text-center my-5"
        message="Please hold tight while we get your items..."
      />
    </div>
  )

  return (
    <>
      { window.location.pathname !== RESUPPLY_THANK_YOU_PATH && <ProgressHeader percentProgress={( currentPageIndex + 1 ) / ( pageNames.length - 1 ) * 100} stepText={`Step ${currentPageIndex + 1}/${pageNames.length-1}`} /> }
      <PageNavigator
        pageNames={pageNames}
        onChange={pageChangeHandler}
        pageClassName="min-h-480 themed-checkboxes px-5"
      >
        { resupplyOptionResponse?.replaceMaskOptions?.length > 0 && <ReplacementMaskSelection resupplyOptionResponse={resupplyOptionResponse} /> }
        <Claims resupplyOptionResponse={resupplyOptionResponse} />
        { medicareChecklistRequired && <MedicareChecklist /> }
        <Address />
        { updateDoctorRequired !== `none` && <DoctorController /> }
        { ( updatePrimaryPayerStatus !== `none` || updateSecondaryPayerStatus !== `none` ) && <Insurance /> }
        <Confirm />
        <ThankYou />
      </PageNavigator>
    </>
  )
}