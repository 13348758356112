import { Tile } from 'components/tile'
import { useNavigate } from 'react-router-dom'
import { ResupplyStorage } from 'apollo/storage'
import { myResupplyId } from '../constants'
import { accountNavOptions } from 'apollo'
import { useReactiveVar } from '@apollo/client'
import { RESUPPLY_CLAIMS_ITEMS_PATH } from 'modules/resupply2'
import { useAccountStore } from '../state'
import { isCurrentDateOrPastDate } from '../../../utils/time'
import Error from '../components/Error'
import { useEffect } from 'react'
import { Product } from '../components/Product'
import { ProductResponse } from 'components/account/YourProducts/ProductType'

function ProductResupply() : JSX.Element | null {
  const navigate = useNavigate()
  const alreadyPlaced = ResupplyStorage.getOrderPlaced()
  const currentOptions = useReactiveVar( accountNavOptions )
  const { equipment, accountTracker } = useAccountStore()
  const myResupplyNavOption = currentOptions.find( ( option ) => { return option.id === myResupplyId })

  // if we do not even have the placeholder image from magento that means the product has been disabled
  const resupplyItems = equipment?.data?.filter( item => Boolean( item?.image?.url ) ) ?? []

  const eligibleItems = resupplyItems.filter( item => isCurrentDateOrPastDate( item.eligible ) )
  const ineligibleItems = resupplyItems.filter( item => !isCurrentDateOrPastDate( item.eligible ) )

  // add Product Resupply to navigate dropdown
  useEffect( () => {
    if ( equipment.data?.length && !myResupplyNavOption && !equipment.error ) accountNavOptions( [{
      label: `Product Resupply`,
      id: myResupplyId
    }, ...currentOptions ] )
  }, [ equipment, myResupplyNavOption ] )

  if ( eligibleItems?.length === 0 || alreadyPlaced || equipment.loading || accountTracker.loading ) return null

  if ( equipment?.error ) return ( <Error title="Product Resupply" id={myResupplyId} fillInTheBlank="resupply information" /> )

  return (
    <Tile
      headerContent="My Supplies Refill Schedule"
      id={myResupplyId}
      {
        ...( ineligibleItems?.length > 0 && {
          footer: <div className="bg-[#DDDDDD] rounded-b-md mx-auto p-5">
            <p className="text-black text-xl mb-5">{`Upcoming`}</p>
            {
              ineligibleItems?.length > 0 &&
              <div className="flex flex-col gap-5 mb-5">
                {
                  ineligibleItems.map( ( item: ProductResponse ) => {
                    const interval = accountTracker?.data?.resupply_status?.[`Resupply Status`]?.find( itemStatus => itemStatus.dme_id === item.hcpc_id )?.service_interval

                    return <Product
                      key={item.hcpc_id} item={item} eligible={false}
                      interval={interval}
                    />
                  })
                }
              </div>
            }
          </div>
        })
      }
    >
      <div className="mb-5 mt-2">
        <p className="text-sleepBlue text-xl mb-6">{`Due Now`}</p>
        <div className="flex flex-col gap-5">
          {
            eligibleItems.map( ( item: ProductResponse ) => {
              const interval = accountTracker?.data?.resupply_status?.[`Resupply Status`]?.find( itemStatus => itemStatus.dme_id === item.hcpc_id )?.service_interval
              return <Product key={item.hcpc_id} item={item} interval={interval} />
            })
          }
        </div>
        <div className="mt-[41px]">
          <button
            className="btn-primary"
            onClick={() => { navigate( RESUPPLY_CLAIMS_ITEMS_PATH ) }}
          >
            {`Go To Resupply Portal`}
          </button>
        </div>
      </div>
    </Tile>

  )
}

export default ProductResupply