import { useState, useRef, useEffect } from 'react'
import { PwdChange } from './PwdChange'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import { generateCustomerTokenMutation, resetPasswordMutation } from 'graphql/mutations'
import { isEmailAvailableQuery } from 'graphql/queries'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import postDemographics from 'utils/auth/demographics'
import { updateCustomerEmailMutation } from 'graphql/mutations/updateCustomerEmail'
import ResetPasswordButton from './ResetPasswordButton'
import { reportToSentry } from 'utils/reportToSentry'
import { getAuthHeader } from 'utils/auth/helpers'
import { useTokenStore } from 'stores/tokenStore'

export const EnableAccountForm = ({email, setError, formValues, setFormValues, resetSuccess, setResetSuccess}) => {

  const [ usernamePwdInputErrors, setUsernamePwdInputErrors ] = useState({})
  const [ displayError, setDisplayError ] = useState( false )
  const [ placeholderEmail, setPlaceholderEmail ] = useState( false )
  const [ emailError, setEmailError ] = useState( `` )
  const [ loading, setLoading ] = useState( false )

  const searchParams = new URLSearchParams( window.location.search )
  const magentoId = searchParams.get( `afmcid` )

  const inputRefs = {
    emailInputRef: useRef( null ),
    passwordConfirmationInputRef: useRef( null ),
    passwordInputRef: useRef( null )
  }

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    errorPolicy: `all`,
    fetchPolicy: `no-cache`,
    variables: {
      email,
      password: formValues.password
    },
    onError: ( error ) => {
      setLoading( false )
      setEmailError( `Your password reset was successful but we ran into an issue attempting to log you in with your new password. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      reportToSentry( new Error( `Pre Claim Account: Error attempting to login user after password reset`, {
        cause: error
      }), {
        magentoId
      })
    }
  })

  const [ resetPassword ] = useMutation( resetPasswordMutation, {
    errorPolicy: `all`,
    variables: {
      resetPasswordToken: searchParams.get( `afmcatoken` ),
      email,
      newPassword: formValues.password
    },
    onCompleted: ( result ) => {
      if ( !result || result?.resetPassword === null || result?.errors?.length ) {
        setLoading( false )
        reportToSentry( new Error( `Pre Claim Account: Error attempting to reset password` ), {
          magentoId,
          email: email,
          emailInput: formValues.email,
          resetSuccess,
          result: JSON.stringify( result ),
          resetPasswordErrors: JSON.stringify( result?.errors )
        })

        return setError( `We encountered an error attempting to set your password. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      }

      setResetSuccess( true )
    },
    onError: ( error ) => {
      setLoading( false )
      reportToSentry( new Error( `Pre Claim Account: Error attempting to reset password`, {
        cause: error
      }), {
        magentoId
      })

      return setError( `We encountered an error attempting to set your password. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
    }
  })

  const [ checkEmailAvailable ] = useLazyQuery( isEmailAvailableQuery, {
    fetchPolicy: `network-only`, // don't cache, use live site data
    onCompleted: ({ isEmailAvailable: { is_email_available }}) => {
      if ( !is_email_available ) {
        setLoading( false )

        return setEmailError( `This email is being used by another account and is currently not available to be claimed. Please try another email.` )
      } else handlePasswordReset()
    },
    onError: ( error ) => {
      setLoading( false )

      reportToSentry( new Error( `Pre-create Account: Error checking email availability`, {
        cause: error
      }), {
        magentoId
      })

      return setEmailError( `We encountered an issue checking this email. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
    }
  })

  const [ updateCustomerEmail ] = useMutation( updateCustomerEmailMutation, {
    variables: {
      email: formValues.email,
      password: formValues.password
    },
    onCompleted: ( data ) => {
      if ( !data?.updateCustomerEmail?.customer?.email ) {
        setLoading( false )
        reportToSentry( new Error( `Pre Claim Account: Error attempting to update user email on magento` ), {
          magentoId
        })

        return setEmailError( `We ran into an issue attempting to update your email. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      }

      handleSuccessfulNavigate()
    },
    onError: ( error ) => {
      setLoading( false )
      setEmailError( `We ran into an issue attempting to update your email. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      reportToSentry( new Error( `Pre Claim Account: Error attempting to update user email on magento`, {
        cause: error
      }), {
        magentoId
      })
    }
  })

  useEffect( () => {
    if ( email ) {
      const emailIsPlaceholder = ( /^patient(\d{1,8})@aeroflowinc.com$/ ).test( email )
      setPlaceholderEmail( emailIsPlaceholder )
      if ( !emailIsPlaceholder ) setFormValues( Object.assign({}, formValues, {
        email
      }) )
    }
  }, [ email ] )

  useEffect( () => {
    if ( resetSuccess ) handleLogin()
  }, [ resetSuccess ] )

  const { setCustomerToken } = useTokenStore()

  const handleChange = ( newVals ) => {
    setEmailError( `` )
    setFormValues(
      {
        ...formValues,
        ...newVals
      }
    )
  }

  const handleLogin = async () => {
    const loginResult = await login()

    if ( loginResult?.data?.generateCustomerToken?.token ) {
      setCustomerToken( loginResult.data.generateCustomerToken.token )

      if ( placeholderEmail ) await handleChangeEmail( magentoId )
      else handleSuccessfulNavigate()
    } else {
      setLoading( false )
      setEmailError( `Your password reset was successful but we ran into an issue attempting to log you in with your new password. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )

      return reportToSentry( new Error( `Pre Claim Account: Error attempting to login user after password reset` ), {
        magentoId
      })
    }
  }

  const handleChangeEmail = async ( magentoId ) => {
    const internalEmailUpdateResponse = await postDemographics( getAuthHeader(), {
      patientId: magentoId,
      emailAddress: formValues.email
    })
      .catch( ( error ) => {
        setEmailError( `We ran into an issue attempting to update your email. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
        reportToSentry( new Error( `Pre Claim Account: Error attempting to update user email on internal`, {
          cause: error
        }), {
          magentoId
        })

        return setLoading( false )
      })

    if ( internalEmailUpdateResponse ) updateCustomerEmail()
    else {
      setEmailError( `We ran into an issue attempting to update your email. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
      setLoading( false )
      reportToSentry( new Error( `Pre Claim Account: Error attempting to update user email on internal` ), {
        magentoId
      })
    }
  }

  const handleSuccessfulNavigate = () => {
    searchParams.delete( `afmcatoken` )
    searchParams.delete( `afmcid` )
    const adjustedURL = new URL( window.location.href )
    adjustedURL.search = searchParams.toString()

    if ( window.location.pathname === `/enable-account` ) adjustedURL.pathname = `account`

    return window.location.href = adjustedURL.href
  }

  const handlePasswordReset = async () => {

    if ( !resetSuccess ) {
      await resetPassword().catch( error => {
        setLoading( false )
        setEmailError( `We ran into an issue attempting to update your email. Please try again or contact customer service at ${AF_SUPPORT_PHONE_NUMBER} if the issue persists.` )
        reportToSentry( new Error( `Pre Claim Account: Error attempting to update user email on magento`, {
          cause: error
        }), {
          magentoId
        })
      })
    } else await handleLogin()
  }

  const handleError = ( name, error ) => {
    setUsernamePwdInputErrors( Object.assign({}, usernamePwdInputErrors, {
      [name]: error
    }) )
  }

  const handleSubmit = async ( e ) => {
    e.preventDefault()
    setEmailError( `` )
    setError( null )
    setLoading( true )

    if ( !Object.keys( usernamePwdInputErrors ).length && Object.values( formValues ).find( input => { return input === `` }) === undefined ) {
      if ( placeholderEmail ) {
        checkEmailAvailable({
          variables: {
            email: formValues.email
          }
        })
      } else await handlePasswordReset()
    } else {
      setDisplayError( true )
      setLoading( false )
    }
  }

  return (
    <form onSubmit={handleSubmit} className="py-6 px-5 md:px-10 my-20 md:my-0 bg-secondaryBlue-tint2 text-center text-black min-height-sm max-w-lg w-full">
      <PwdChange
        formValues={formValues}
        handleChange={handleChange}
        handleInputError={handleError}
        formInputErrors={usernamePwdInputErrors}
        setUsernamePwdInputErrors={setUsernamePwdInputErrors}
        displayError={displayError}
        setDisplayError={setDisplayError}
        inputRefs={inputRefs}
        disableUsername={!placeholderEmail}
        disableAll={loading || resetSuccess}
      />
      <ResetPasswordButton
        disabled={Object.keys( usernamePwdInputErrors ).length || Object.values( formValues ).find( input => { return input === `` }) !== undefined}
        loading={loading}
        resetSuccess={resetSuccess}
      />
      {
        emailError &&
          <p className="text-error max-w-xl font-light">{emailError}</p>
      }
    </form>
  )
}

EnableAccountForm.propTypes = {
  email: PropTypes.string,
  setError: PropTypes.func,
  formValues: PropTypes.object,
  setFormValues: PropTypes.func,
  resetSuccess: PropTypes.bool,
  setResetSuccess: PropTypes.func
}

export default EnableAccountForm