import {
  SNL_MACHINE_BRAND_PATH,
  SNL_MACHINE_MODEL_PATH,
  SNL_MASK_BRAND_PATH,
  SNL_MASK_MODEL_PATH,
  SNL_MASK_TYPE_PATH
} from '../constants'
import create from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export type SNLFormDataType = {
  stepComplete: number;
  lastOrderCpapSupplies: string;
  lastOrderCpapMachine: number;
  userKnowsMaskDetailsFlag: boolean;
  userKnowsMachineDetailsFlag: boolean;
  cpapMaskDmeID: string;
  cpapMaskBrand: string;
  cpapMaskModel: string;
  cpapMaskHcpc: string;
  cpapMaskSize: string;
  cpapMachineDmeID: string;
  cpapMachineBrand: string;
  cpapMachineModel: string;
  cpapMachineHcpc: string;
  cpapMachineHumidifierFlag: boolean;
  tubingDmeID: string;
  tubingType: string;
  lastOrderHadHeadgearFlag: number;
  lastOrderHadWaterChamberFlag: number;
  userRequestsContactFlag: boolean;
  intentOrderNewEquipment: boolean;
  lastOrderCpapMask: string;
  lastOrderCpapMaskHeadgear: string;
  satisfiedWithCurrentMask: boolean;
  webConfirmed: boolean;
  patientConfirmed: boolean;
  cpapMaskType?: string;
}

interface SNLState {
  formValues: SNLFormDataType;
  activeStep: number;
  highestStep: number;
  doesNotWantMask: boolean;
  snlEmail: string;
  clearSession: () => void;
  snlEditSelection: () => {
    label: string;
    value: string;
    step: string;
    path: string;
  }[];
  setSnlEmail: ( value: string ) => void;
  setDoesNotWantMask: ( value: boolean ) => void;
  snlUpdateSession: ( formValues: SNLFormDataType, activeStep: number, highestStep: number ) => void;
}

export const useSNLStore = create<SNLState>()(
  persist(
    ( set, get ) => ({
      formValues: {
        stepComplete: 0, // number
        lastOrderCpapSupplies: ``, // string
        lastOrderCpapMachine: 0, // number
        userKnowsMaskDetailsFlag: false, // boolean
        userKnowsMachineDetailsFlag: false, // boolean
        cpapMaskDmeID: ``, // string
        cpapMaskBrand: ``, // string
        cpapMaskModel: ``, // string
        cpapMaskSize: ``, // string
        cpapMaskHcpc: ``, // string
        cpapMachineDmeID: ``, // string
        cpapMachineBrand: ``, // string
        cpapMachineModel: ``, // string
        cpapMachineHcpc: ``, // string
        cpapMachineHumidifierFlag: false, // boolean
        tubingDmeID: ``, // string
        tubingType: ``, // string
        lastOrderHadHeadgearFlag: 0, // number
        lastOrderHadWaterChamberFlag: 0, // number
        userRequestsContactFlag: false, // boolean
        intentOrderNewEquipment: false, // boolean
        lastOrderCpapMask: ``, // string
        lastOrderCpapMaskHeadgear: ``, // string
        satisfiedWithCurrentMask: false, // boolean
        webConfirmed: false, // boolean
        patientConfirmed: false, // boolean
        cpapMaskType: `` // optional string
      },
      activeStep: 1, // Default to 1
      highestStep: 1, // Default to 1
      snlEmail: ``,
      doesNotWantMask: false,
      setDoesNotWantMask: ( value: boolean ) => set({
        doesNotWantMask: value
      }),
      setSnlEmail: ( value: string ) => set({
        snlEmail: value
      }),
      snlEditSelection: () => {
        const { formValues } = get() as SNLState

        const editSelection = []

        if ( formValues?.cpapMaskType ) editSelection.push({
          label: `Select your current mask type`,
          value: formValues?.cpapMaskType,
          step: `cpapMaskType`,
          path: SNL_MASK_TYPE_PATH
        })

        if ( formValues?.cpapMaskType && formValues?.cpapMaskBrand ) editSelection.push({
          label: `Select your current mask brand`,
          value: formValues?.cpapMaskBrand,
          step: `cpapMaskBrand`,
          path: SNL_MASK_BRAND_PATH
        })

        if ( formValues?.cpapMaskModel ) editSelection.push({
          label: `Select your current mask model`,
          value: formValues?.cpapMaskModel,
          step: `cpapMaskModel`,
          path: SNL_MASK_MODEL_PATH
        })

        if ( formValues?.cpapMachineBrand ) editSelection.push({
          label: `Select your current machine brand`,
          value: formValues?.cpapMachineBrand,
          step: `cpapMachineBrand`,
          path: SNL_MACHINE_BRAND_PATH
        })

        if ( formValues?.cpapMachineModel ) editSelection.push({
          label: `Select your current machine model`,
          value: formValues?.cpapMachineModel,
          step: `cpapMachineModel`,
          path: SNL_MACHINE_MODEL_PATH
        })

        return editSelection
      },
      snlUpdateSession: ( formValues: SNLFormDataType, activeStep: number, highestStep: number ) =>
        set({
          formValues: formValues || {},
          activeStep: activeStep || 1,
          highestStep: highestStep || 1
        }),
      clearSession: () =>
        set({
          formValues: {
            stepComplete: 0,
            lastOrderCpapSupplies: ``,
            lastOrderCpapMachine: 0,
            userKnowsMaskDetailsFlag: false,
            userKnowsMachineDetailsFlag: false,
            cpapMaskDmeID: ``,
            cpapMaskBrand: ``,
            cpapMaskModel: ``,
            cpapMaskSize: ``,
            cpapMaskHcpc: ``,
            cpapMachineDmeID: ``,
            cpapMachineBrand: ``,
            cpapMachineModel: ``,
            cpapMachineHcpc: ``,
            cpapMachineHumidifierFlag: false,
            tubingDmeID: ``,
            tubingType: ``,
            lastOrderHadHeadgearFlag: 0,
            lastOrderHadWaterChamberFlag: 0,
            userRequestsContactFlag: false,
            intentOrderNewEquipment: false,
            lastOrderCpapMask: ``,
            lastOrderCpapMaskHeadgear: ``,
            satisfiedWithCurrentMask: false,
            webConfirmed: false,
            patientConfirmed: false,
            cpapMaskType: ``
          },
          activeStep: 0,
          highestStep: 0,
          doesNotWantMask: false,
          snlEmail: ``
        })
    }),
    {
      name: `snlSessionStorage`,
      storage: createJSONStorage( () => sessionStorage )
    }
  )
)


export function getSessionData() {
  const { formValues, activeStep, highestStep } = useSNLStore.getState() as SNLState
  return {
    formValues,
    activeStep,
    highestStep
  }
}