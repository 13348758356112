import React from 'react'
import TrackerStepIcon from '../../TrackerStepIcon'
import { cartIconWhite } from 'resources/images'
import StepContentRenderer from '../../StepContentRenderer'
import usePatientRouting from 'hooks/PatientRouting/usePatientRouting'

type ActionNeededStepProps = {
  stepStatus: boolean;
  isCurrentStep?: boolean;
}

function ActionNeededStep({ stepStatus, isCurrentStep } : ActionNeededStepProps ) : JSX.Element {
  const { data } = usePatientRouting()
  const hash = data?.patient_hash

  // handling the copy for this step within the component because we need to dynamically create the stepLinkUrl
  const actionNeededCopy = {
    notCompletedStepHeading: `Confirm Your Order`,
    currentStepDetails: `Confirm your order to get your machine shipped.`,
    stepLinkText: `Confirm order`,
    stepLinkUrl: hash ? `/payments/summary?sgh=${hash}` : ``,
    stepToolTip: `Once all information is verified and required documentation is obtained, you must confirm your order before we can ship your machine out.`,
    completedStepHeading: `Order Confirmed`
  }

  return (
    <div className="flex">
      <TrackerStepIcon imageSrc={cartIconWhite} stepStatus={stepStatus} isCurrentStep={isCurrentStep} />
      <StepContentRenderer
        stepStatus={stepStatus}
        isCurrentStep={isCurrentStep}
        stepCopy={actionNeededCopy}
      />
    </div>
  )
}

export default ActionNeededStep
