
import { useEffect } from "react"
import { getHash } from "modules/clinicalScheduling/utils"
import { useNavigate } from "react-router-dom"
import { ResupplyStorage } from 'apollo/storage'
import { useClaimedState, useResupplySetting } from 'modules/resupply2'
import AobPaymentsPortal from "./AobPaymentsPortal"

const AobPayments = () => {
  const claimedItems = useClaimedState()[0]
  const { patientId } = useResupplySetting()[0]

  const navigate = useNavigate()

  useEffect( () => {
    if ( !getHash() && ( !claimedItems?.length || !ResupplyStorage.getOrderPlaced() || !patientId ) ) navigate( `/404` )
  }, [] )

  return <AobPaymentsPortal type={getHash() ? `hash` : `token`} />
}

export default AobPayments