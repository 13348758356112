import { HealthTapScreenerResponse } from "modules/sleepNewLeads/State/healthTapSurveyStore"
import { SNLFormDataType } from "modules/sleepNewLeads/State/snlStorage"
import { QualifyFormFields } from "stores/qualifyFieldsStore"
import { HealthtapInsurance, HealthtapProduct, HealthTapScreener } from "types/healthtapScreener"
import { addMagentoEnvAuthHeaders } from "utils/magentoEnvAuthHeaders"

export const submitHealthTapScreener = async ( data: HealthTapScreener, token: string ) => {
  const response = await fetch( `${process.env.REACT_APP_SNL_URL}/healthtap/magic-link`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'Authorization': `Bearer ${token}`,
      ...addMagentoEnvAuthHeaders()
    },
    body: JSON.stringify( data )
  })

  return await response.json()
}

export const buildHealthTapProductArray = ( formFields: SNLFormDataType ) : HealthtapProduct[] => {
  const products = []

  if ( formFields.cpapMachineDmeID ) {
    products.push({
      product_code: formFields.cpapMachineDmeID?.toString(),
      product_name: `${formFields.cpapMachineBrand} ${formFields.cpapMachineModel}`,
      hcpcs_code: formFields.cpapMachineHcpc
    })
  }

  if ( formFields.cpapMaskDmeID ) {
    products.push({
      product_code: formFields.cpapMaskDmeID?.toString(),
      product_name: `${formFields.cpapMaskBrand} ${formFields.cpapMaskModel}`,
      hcpcs_code: formFields.cpapMaskHcpc
    })
  }

  if ( formFields.tubingDmeID ) {
    products.push({
      product_code: formFields.tubingDmeID?.toString(),
      product_name: `${formFields.tubingType} Tubing`
    })
  }

  return products
}

export const findHealthtapInsuranceMatch = ( insurance: string ) => {
  const insuranceTrimmed = insurance.trim()
  switch ( insuranceTrimmed ) {
  case `United Healthcare (UHC)`:
    return `UNITED HEALTHCARE`

  case `Aetna`:
    return `AETNA`

  case `Blue Cross and Blue Shield of Texas`:
    return `TEXAS BLUE SHIELD`

  case `UMR`:
    return `UNITED MEDICAL RESOURCES`

  case `BlueCross BlueShield of Tennessee`:
    return `TENNESSEE BLUE SHIELD`

  case `Blue Cross and Blue Shield of Illinois`:
    return `ILLINOIS BLUE SHIELD`

  case `Humana`:
    return `HUMANA`

  case `Blue Cross and Blue Shield of Oklahoma`:
    return `OKLAHOMA BLUE SHIELD`

  case `Cigna`:
    return `CIGNA HEALTH PLANS`

  case `BlueCross BlueShield of South Carolina`:
    return `SOUTH CAROLINA BLUE SHIELD`

  case `AETNA MEDICARE ADVANTAGE`:
    return `AETNA - GOLDEN MEDICARE (MEDICARE ADVANTAGE PLAN)`

  case `CIGNA EVICORE GREAT WEST`:
    return `CIGNA SELECT (FORMERLY GREAT WEST HEALTHCARE)`

  case `HUMANA MEDICARE NATIONAL`:
    return `HUMANA HEALTH PLAN`

  case `UHC MEDICARE ADVANTAGE`:
    return `UNITEDHEALTHCARE GROUP MEDICARE ADVANTAGE`

  case `HUMANA MEDICARE HMO NEW`:
    return `HUMANA HEALTH PLAN - HMO`

  case `SCOTT & WHITE FIRSTCARE COMMERCIAL`:
    return `SCOTT AND WHITE HEALTH PLAN`

  default:
    return null
  }

}

export const buildHealthTapInsuranceArray = ( formFields: QualifyFormFields ) : { insurances: HealthtapInsurance[], match: boolean } => {
  const insurancePrimaryMatch = findHealthtapInsuranceMatch( formFields.insuranceType )
  const insuranceSecondaryMatch = findHealthtapInsuranceMatch( formFields.secondaryInsuranceType )

  const insurances = [ {
    name: insurancePrimaryMatch ?? formFields.insuranceType,
    member_id: formFields.memberID,
    rank: `primary`
  } as HealthtapInsurance ]

  if ( formFields.secondaryInsuranceType && formFields.secondaryMemberID ) {
    insurances.push({
      name: insuranceSecondaryMatch ?? formFields.secondaryInsuranceType,
      member_id: formFields.secondaryMemberID,
      rank: `secondary`
    } as HealthtapInsurance )
  }

  return {
    insurances,
    match: Boolean( ( insurancePrimaryMatch && insuranceSecondaryMatch ) || ( !formFields.secondaryInsuranceType && !formFields.secondaryMemberID && insurancePrimaryMatch ) )
  }
}

export const buildHealthTapScreenerArray = ( responses: HealthTapScreenerResponse, machineBrand: string ) => {

  const lowerCaseMachineBrand = machineBrand.toLowerCase()

  // map machine brand to healthtap answer id
  const q2Answer = lowerCaseMachineBrand.includes( `resmed` ) ? `sleep_apnea_q_2_a1` : lowerCaseMachineBrand.includes( `resvent` ) ? `sleep_apnea_q_2_a2` : lowerCaseMachineBrand.includes( `3b` ) ? `sleep_apnea_q_2_a3` : `sleep_apnea_q_2_a4`

  return {
    q_1_answer_ids: [ `sleep_apnea_q_1_a1` ], // for SNL this will always be the case since patients will be refilling, on qualify form if they select anything else they won't get into SNL
    q_2_answer_ids: [ q2Answer ],
    q_3_answer_ids: [ responses.apneaType ],
    q_4_answer_ids: [ responses.apneaFrequency ],
    q_5_answer_ids: Object.keys( responses.apneaHealthCondition ).filter( key => responses.apneaHealthCondition[key] ),
    q_6_answer_ids: [ responses.healthtapBloodPressure ],
    q_7_answer_ids: [ responses.apneaLastDiscussed ]
  }
}