import { useEffect, useState } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import EnableAccountForm from 'components/enableAccountForm'
import { sleepLaptopImg } from 'resources/images'
import { reportToSentry } from 'utils/reportToSentry'
import Background from './Background'
import { removeSearchParams, getEmailFromResetToken } from './utils/utils'
import { useSearchParams } from 'react-router-dom'

export default function EnableAccount() {

  const [ error, setError ] = useState( null )
  const [ loading, setLoading ] = useState( true )
  const [ email, setEmail ] = useState( null )
  const [ resetSuccess, setResetSuccess ] = useState( false )
  const [ formValues, setFormValues ] = useState({
    email: ``,
    password: ``,
    passwordConfirmation: ``
  })

  const [ searchParams, setSearchParams ] = useSearchParams()
  const magentoId = searchParams.get( `afmcid` )
  const resetToken = searchParams.get( `afmcatoken` )

  useEffect( () => {
    // we don't have what we need to validate the reset token
    if ( !magentoId || !resetToken ) return () => { return exit() }

    getEmailFromResetToken( magentoId, resetToken )
      .then( data => {
        if ( !data?.length || !data[0]?.email || data[0]?.error ) {
          // create a new error of string if available
          if ( data[0]?.error && typeof data[0].error === `string` ) {

            const errorString = data[0].error
            // each of these error strings has a different reset password token. this gen's a new error in sentry for each time
            // to cut down on the sum total into 1 error message we're removing the token from the error message reported to sentry
            const serializedErrorString = errorString.includes( `Something went wrong. Error was: No such entity with rp_token` ) ?
              errorString.split( ` = ` )[0]
              :
              errorString

            reportToSentry( new Error( serializedErrorString ), {
              magentoId,
              resetToken,
              magentoResponse: JSON.stringify( data )
            })
          } else {
            // generic new error
            reportToSentry( new Error( `getEmailFromResetToken failure: no string error` ), {
              magentoId,
              resetToken,
              magentoResponse: JSON.stringify( data )
            })
          }

          return exit()

        }
        setEmail( data[0].email )
        setLoading( false )
      })
      .catch( error => {
        reportToSentry( new Error( `Pre-create Account: Error getting email from reset token and id` ), {
          magentoId,
          resetToken,
          magentoError: JSON.stringify( error )
        })

        return exit()
      })


  }, [] )

  const exit = () => { setSearchParams( removeSearchParams( searchParams ) ) }


  return (
    <div className="bg-hero-image bg-cover bg-no-repeat bg-fixed text-graphite">
      <div className="flex flex-col items-center justify-center px-5 pt-10 pb-16 md:pt-24 md:pb-36">
        {
          loading ?
            <div className="md:p-40 sm:p-20">
              <LoadingSpinner />
            </div>
            :
            <>
              <div className="md:mb-16 max-w-xl text-center font-bold">
                <h1 className="mb-10 text-3xl md:text-5xl">{`Create Your Online Account`}</h1>
                <p className="text-lg font-light">{`With this account, you can track order status, reorder fresh supplies, and see other important updates. Simply set your password below.`}</p>
              </div>

              <div className="flex w-full flex-wrap items-start gap-20 justify-center">
                <div className="flex flex-col md:gap-10 w-full max-w-xl">
                  <EnableAccountForm
                    email={email}
                    setError={setError}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    resetSuccess={resetSuccess}
                    setResetSuccess={setResetSuccess}
                  />
                  {
                    error &&
                      <p className="text-error font-light text-lg mb-12 text-center max-w-lg">{error}</p>
                  }
                </div>
                <div className="relative hidden lg:block">
                  <img
                    src={sleepLaptopImg}
                    alt="Sleep Laptop Image"
                    width={360}
                    height={360}
                    className="bg-white rounded-sm relative z-10"
                  />
                  <Background className="bg-secondaryBlue-tint3" />
                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}
