import React from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import PropTypes from 'prop-types'

function ResetPasswordButton({
  disabled,
  loading,
  resetSuccess
}) {
  return (
    <div className="m-auto mt-5 w-1/2 min-w-fit">
      <button
        type="submit"
        className="btn-primary whitespace-nowrap"
        disabled={disabled || loading}
      >
        {
          loading ?
            <div className="flex items-center gap-3 justify-center"><LoadingSpinner rings={3} height="1.5rem" width="1.5rem" />
              <p>{!resetSuccess ? `Resetting password` : `Completing account`}</p>
            </div>
            :
            !resetSuccess ?
              `Create account`
              :
              `Complete account`
        }
      </button>
    </div>
  )
}

ResetPasswordButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  resetSuccess: PropTypes.bool
}

export default ResetPasswordButton